$primary: #FA5000;

// Here you can add other styles
.sidebar {
    background: #101828 !important;
}

.fs-12 {
    font-size: 12px !important;
}

.fs-14 {
    font-size: 14px !important;
}

.fs-18 {
    font-size: 18px !important;
}

.fs-22 {
    font-size: 22px !important;
}

.fs-24 {
    font-size: 24px !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.flex-grow-5 {
    flex-grow: 5 !important;
}

.form-control:focus, .form-select:focus {
    border-color: $primary !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 1px $primary !important;
}

.input-group:focus-within {
    outline: 1px solid $primary;
}

.input-group:focus-within *:focus {
    border-color: transparent !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0px $primary !important;
}

.form-check-input:checked {
    background-color: $primary !important;
    border-color: $primary !important;
}

.bg-orange {
    background-color: $primary !important;
}

.text-primary {
    color: $primary !important;
}

.text-orange, a {
    color: $primary !important;
}

.page-item {
    a {
        color: rgba(108, 117, 125, 1)!important;
    }
}

.page-item.active {
    a {
        color: black !important;
    }
}

.page-item.prev-item, .page-item.next-item {
    a {
        color: $primary !important;
    }
}

.page-item.prev-item.disabled, .page-item.next-item.disabled {
    a {
        color: rgba(108, 117, 125, 0.65)!important;
    }
}

.form-switch .form-check-input:checked {
    background-color: $primary !important;
    border-color: $primary !important;
}

.dropdown-item:hover {
    background-color: $primary !important;
    color: white !important;
}

.btn-primary {
    background-color: $primary !important;
    border-color: $primary !important;
}

.btn-outline-primary {
    color: $primary !important;
    border-color: $primary !important;
}

.btn-outline-primary:hover {
    color: white !important;
    background-color: $primary !important;
}

.ql-editor {
    overflow-y: scroll !important;
    resize: vertical !important;
    min-height: 18em !important;
}

.tournament-banner {
    aspect-ratio: 23/9 !important;
}

.swal2-confirm {
    display: none !important;
}

.swal2-html-container {
    margin: 0 !important;
}

.swal2-popup {
    padding: 0 !important;
}

.swal2-actions {
    display: none !important;
}

.swal2-close:focus {
    box-shadow: none !important;
}

@media (min-width: 768px) {
    .sidebar.sidebar-fixed {
        z-index: 1 !important;
    }
}

.header.header-sticky {
    z-index: 1 !important;
}

.white-break-spaces {
    white-space: break-spaces !important;
}

.overview-rich-text {
    img {
        max-width: 100% !important;
        cursor: default !important;
    }
}

@media only screen and (max-width: 575px) {
    .w-xs-100 {
        width: 100% !important;
    }
}

@media only screen and (min-width: 576px) {
    .tournament-input {
        min-width: 400px !important;
    }
}
