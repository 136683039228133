// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

$primary: #101828;

// @import '~bootstrap/scss/functions';
// @import '~bootstrap/scss/variables';
// @import '~bootstrap/scss/mixins/_breakpoints';

// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

// // layout
// @import "bootstrap/scss/bootstrap";
// @import 'bootstrap/scss/functions';
// @import 'bootstrap/scss/variables';
// @import 'bootstrap/scss/mixins/_breakpoints';

@import "layout";
@import "example";

// If you want to add custom CSS you can put it here.
@import "custom";
